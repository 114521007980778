<!-- Contact Us Form Start -->
<div style="position: relative;">
  <div class="top-svg svg-div">
    <img firebaseImgLoader="assets/icons/wave.svg">
  </div>
  <app-title title="Contact Us"></app-title>

  <div class="websites-wrapper">
    <ng-container *ngFor="let contact of contactUs">
      <a [href]="contact.url" target="_blank">
        <oth-brand-button class="oth-brand-button" [icon]="contact.cssClass" [text]="contact.name">
        </oth-brand-button>
      </a>
    </ng-container>
  </div>
</div>
<!-- Map -->
<!-- <iframe loading="lazy" referrerpolicy="no-referrer-when-downgrade"
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.0292959133208!2d79.8600206107819!3d13.09732988717639!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5291a0bdf0b20b%3A0x7d2f29e2f6db5698!2sMobTechi!5e0!3m2!1sen!2sin!4v1741369390267!5m2!1sen!2sin"></iframe> -->