import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CONFIG } from 'src/config';

export interface SeoMetaData {
  keywords: string[];
  robots: string[];
  title: string;
  description: string;
  image?: string;
  ogImageWidth?: string;
  ogImageHeight?: string;
  canonicalUrl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SeoService {

  constructor(private titleService: Title, private metaTagService: Meta) {}

  public setMetaData(seo: SeoMetaData): void {
    try {
      this.titleService.setTitle(seo.title);
      this.cleanUpMetaData();
      const tags = [
        { name: 'keywords', content: seo.keywords.join(', ') },
        { name: 'robots', content: seo.robots.join(', ') },
        { name: 'description', content: seo.description },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: seo.title },
        { property: 'og:description', content: seo.description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:image', content: seo.image },
        { property: 'og:image:width', content: seo.ogImageWidth || '1200' },
        { property: 'og:height', content: seo.ogImageHeight || '630' },
        { property: 'og:site_name', content: CONFIG.website },
        { property: 'article:author', content: CONFIG.title },
        { property: 'article:publisher', content: CONFIG.contactUs.mail.url },
        ...(seo.canonicalUrl ? [{ rel: 'canonical', href: seo.canonicalUrl }] : [])
      ];
      this.metaTagService.addTags(tags);
    } catch (e) {
      console.error('Error setting Meta Tags: ', e);
    }
  }

  private cleanUpMetaData(): void {
    const propertiesToRemove = [
      'og:title', 'og:description', 'og:image', 'og:url', 'og:type', 
      'og:site_name', 'og:image:width', 'og:image:height', 
      'article:author', 'article:publisher'
    ];
    propertiesToRemove.forEach(property => this.metaTagService.removeTag(`property="${property}"`));
    this.metaTagService.removeTag('name=keywords');
    this.metaTagService.removeTag('name=robots');
    this.metaTagService.removeTag('name=description');
    this.metaTagService.removeTag('rel=canonical');
  }
}
