export const CONFIG = {
  version: "1.2.0",
  title: 'MobTechi',
  since: '30-O5-2022',
  description: `We craft innovative digital experiences that bring people and technology together. Our websites, mobile apps, and games are designed to deliver seamless, engaging, and intuitive interactions that make life easier and more enjoyable. With a passion for user-centric design, we create high-quality digital products that are both functional and beautiful.`,
  website: "https://mobtechi.com",
  email: "mobtechi.team@gmail.com",
  logo: "https://firebasestorage.googleapis.com/v0/b/mobtechi.appspot.com/o/assets%2Flogo.png?alt=media",
  contactUs: {
    mail: {
      name: "Mail",
      cssClass: "at",
      url: "mailto:mobtechi.team@gmail.com",
    },
    whatsapp: {
      name: "WhatsApp",
      cssClass: "whatsapp",
      url: "https://chat.whatsapp.com/KsTPXx5Aejh1wXdfRkoVpw"
    },
    github: {
      name: "GitHub",
      cssClass: "github",
      url: "https://github.com/orgs/MobTechi/repositories",
    }
  },
  socialMedia: {
    facebook: {
      name: "facebook",
      cssClass: "facebook",
      url: "https://www.facebook.com/profile.php?id=61573412022739"
    },
    instagram: {
      name: "Instagram",
      cssClass: "instagram",
      url: "https://www.instagram.com/mobtechi",
    },
    youtube: {
      name: "YouTube",
      cssClass: "youtube",
      url: "https://www.youtube.com/@mobtechi",
    },
    googleplay: {
      name: "Play Store",
      cssClass: "google-play",
      url: "https://play.google.com/store/apps/developer?id=MobTechi",
    },
  }
};
